import styles from './confirmBackButton.module.scss'

const ConfirmBackButton = ({ backLink, nameBackLink, onButtonClicked }) => {
  return (
    <div className={styles.content} data-form-type="card">
      <a href={backLink} onClick={onButtonClicked} className={styles.btn}>
        <span className={styles.btn__circle}></span>
        <span className={styles.btn__filledCircle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styles.btn__icon}
            viewBox="0 0 16 16"
          >
            <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />{' '}
          </svg>
        </span>
        <span className={styles.btn__text}>{nameBackLink}</span>
      </a>
    </div>
  )
}

export default ConfirmBackButton
