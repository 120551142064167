import styles from './forwardButton.module.scss'

const ForwardButton = ({ forwardLink, nameForwardLink }) => {
  return (
    <div className={styles.content} data-form-type="card">
      <a href={forwardLink} className={styles.btn}>
        <span className={styles.btn__circle}></span>
        <span className={styles.btn__filledCircle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={styles.btn__icon}
            viewBox="0 0 16 16"
          >
            <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
          </svg>
        </span>
        <span className={styles.btn__text}>{nameForwardLink}</span>
      </a>
    </div>
  )
}

export default ForwardButton
